import { ref } from '#imports'
import { defu } from 'defu'
import type { UnwrapRef } from 'vue'

export interface TableHeader<T> {
  /** Align the content of the column */
  align?: 'left' | 'right' | 'center'
  /** Will override the value of the object's `key` to show this  */
  cellText?: keyof T
  /** Disables sorting for this column */
  disableSort?: boolean
  /** When true the column will expand/fill available space */
  expand?: boolean
  /** Use the fixed width font */
  fixedWidth?: boolean
  /** When true this will hide the whole column */
  hidden?: boolean
  /** Adds an icon to the header */
  icon?: string
  /** Hides the text in the column header */
  iconOnly?: boolean
  isTitle?: boolean
  /** The key name of the property on the item object */
  key: keyof T
  multiline?: boolean
  /** This will give a max width of w-prose */
  prose?: boolean
  /** Optional, use this to sort on something other than `key` */
  sortKey?: keyof T
  sticky?: boolean
  text: string
  /**
   * Will set the font to display (Poppins)
   * @optional
   */
  useDisplayFont?: boolean
  skeletonCellSize?: string
}

type sortValue = 'up' | 'down' | undefined

export interface SortState<U> {
  column: U
  sort: sortValue
}

export const useTableSort = <T>(
  headers: TableHeader<T>[],
  options: SortState<TableHeader<T>['key']>,
  callback?: (sortState: SortState<T>) => void,
) => {
  const sortState = ref<SortState<TableHeader<T>['key']>>(
    defu(options, {
      column: headers?.find(({ disableSort }) => !disableSort)?.key || '',
      sort: 'up' as sortValue,
    }),
  )

  const getSortState = (name: UnwrapRef<keyof T>) => {
    return sortState.value.column === name ? sortState.value.sort : undefined
  }

  const setSortState = (name: keyof T) => {
    sortState.value =
      sortState.value.column === name
        ? {
            ...sortState.value,
            sort:
              sortState.value.sort === 'up'
                ? 'down'
                : sortState.value.sort === 'down'
                ? undefined
                : 'up',
          }
        : {
            column: name,
            sort: 'up',
          }
    if (callback) {
      callback(sortState.value)
    }
  }

  return {
    getSortState,
    setSortState,
    sortState: sortState,
  }
}
