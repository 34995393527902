import type { Api } from '@rialtic/api'

export const getRecommendedInsight = (insights?: Api.ProcessedInsight[]) =>
  insights?.find((insight) => insight.is_recommendation)

export const getInsightDecision = (
  recommendedInsight?: Api.ProcessedInsight,
) => {
  if (!recommendedInsight) return
  return {
    status: recommendedInsight.insight.insight.type,
    reason: recommendedInsight.insight.insight.description,
  }
}

export const getInsightBadgeLabel = (
  recommendedInsight?: Api.ProcessedInsight,
) => {
  if (!recommendedInsight) return
  return recommendedInsight?.insight?.insight?.type ===
    'Claim Line Partially Payable'
    ? 'Modify'
    : 'Not Payable'
}
